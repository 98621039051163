import React from 'react';
import ButtonImage from './buymeacoffee-default-orange.webp';

const ByMeCoffee = () => {
  return (
    <a
      href="https://www.buymeacoffee.com/denys"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={ButtonImage}
        alt="Buy Me A Coffee"
        style={{
          height: '51px',
          width: '217px',
          borderRadius: '5px',
          boxShadow: '3px 4px 4px rgba(190,190,190,.5)',
          marginBottom: 0,
        }}
      />
    </a>
  );
};

export default ByMeCoffee;

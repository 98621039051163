import React from 'react';
import { DiscussionEmbed } from 'disqus-react';
import styles from './Disqus.module.css';

const Disqus = (props) => {
  const disqusShortname = 'denys-vuika';
  const disqusConfig = {
    url: props.article.url,
    title: props.article.title,
  };
  return (
    <div className={styles.container}>
      <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
    </div>
  );
};

export default Disqus;

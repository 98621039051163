import React from 'react';
import styles from './BookReference.module.css';
import image from '../../../content/assets/developing-with-angular.png';

const BookReference = ({ title, url, description, host }) => {
  return (
    <div>
      <div className={styles.bookRef}>
        <a className={styles.bookDetails} href={url} title={url} rel="nofollow">
          <strong className={styles.bookTitle}>{title}</strong>
          <br />
          <em className={styles.bookDescription}>{description}</em>
          {host}
        </a>
        <a
          className={styles.bookImage}
          href={url}
          title={url}
          rel="nofollow"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </div>
    </div>
  );
};

export default BookReference;

import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SiteMetadata from '../components/SiteMetadata/SiteMetadata';
import Layout from '../components/Layout/Layout';
import Pagination from '../components/Pagination/Pagination';
import gatsbyIcon from '../../content/assets/avatar-512x512.jpg';
import styles from './BlogPost.module.css';
import PostDetails from '../components/PostDetails/PostDetails';
import PostTags from '../components/PostTags/PostTags';
import BookReference from '../components/BookReference/BookReference';
import Disqus from '../components/Disqus/Disqus';
import ByMeCoffee from '../components/ByMeCoffee/ByMeCoffee';

const BlogPost = ({ data, location, pageContext }) => {
  const { previous, next } = pageContext;
  const { title } = data.site.siteMetadata;
  const post = data.markdownRemark;
  const readingTime = post.fields.readingTime.text;
  const tags = post.frontmatter.tags || [];

  // eslint-disable-next-line no-console
  // console.log(data);

  let headerImage;
  if (post.frontmatter.image) {
    headerImage = (
      <Img
        className={styles.headerImage}
        fluid={post.frontmatter.image.childImageSharp.fluid}
      />
    );
  }

  const url = data.site.siteMetadata.siteUrl;
  const thumbnail =
    (post.frontmatter.image &&
      post.frontmatter.image.childImageSharp.resize.src) ||
    gatsbyIcon;

  // pathname={post.fields.slug}
  return (
    <Layout title={title} location={location}>
      <SiteMetadata
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        thumbnail={thumbnail && url + thumbnail}
        pathname={location.pathname}
      />
      <article className={styles.post}>
        <h1 className={styles.postTitle}>{post.frontmatter.title}</h1>
        <PostDetails date={post.frontmatter.date} readingTime={readingTime} />
        {post.frontmatter.description && <p>{post.frontmatter.description}</p>}
        {headerImage}
      </article>
      <article
        className={styles.post}
        dangerouslySetInnerHTML={{ __html: post.html }}
      />
      <PostTags tags={tags} />
      <Pagination previous={previous} next={next} />

      <div className={styles.post}>
        <iframe
          src="https://github.com/sponsors/DenysVuika/card"
          title="Sponsor DenysVuika"
          height="225"
          width="600"
          style={{ border: 0 }}
        ></iframe>
      </div>

      <article className={styles.post}>
        <ByMeCoffee />
      </article>

      <div className={styles.post}>
        <BookReference
          title="Developing with Angular"
          url="https://leanpub.com/developing-with-angular/"
          description="Practical journey with Angular framework, ES6, TypeScript, Webpack and Angular CLI."
          host="leanpub.com"
        />
      </div>

      <div className={styles.post}>
        <Disqus
          article={{
            url: `${url}${location.pathname}`,
            title: `${post.frontmatter.title}`,
          }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        image {
          childImageSharp {
            resize(width: 1000, height: 420) {
              src
            }
            fluid(maxWidth: 768) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;

export default BlogPost;

import React from 'react';
import { Link } from 'gatsby';
import styles from './Pagination.module.css';

interface PostInfo {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

export interface PaginationProps {
  previous: PostInfo;
  next: PostInfo;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { previous, next } = props;

  return (
    <div className={styles.container}>
      <ul className={styles.pagination}>
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

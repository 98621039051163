import React from 'react';
import { Link } from 'gatsby';
import styles from './PostTags.module.css';

const PostTags = ({ tags }) => {
  const postTags = tags || [];

  return (
    <div className={styles.container}>
      <ul className={styles.tagList}>
        {postTags.map((tag) => {
          return (
            <li key={tag}>
              <Link to={`/tagged/${tag}`}>{tag}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PostTags;
